import { NavLink, useNavigate } from "react-router-dom";
import DashboardLayout from "@/layouts/dashboard-layout";
import {
  InteractiveLessonCard,
  DashBoardTeacherCard,
  ScheduleCard,
  BlogCard,
} from "./interactive-lesson-card";
import { useEffect } from "react";
import { fetchBlogs } from "@/redux/slice/blog";
import { useAuth } from "@/contexts/auth-context";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

export const Home = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchBlogs({ token: token || "" }));
  }, [dispatch, token]);

  const { data, loading } = useAppSelector((state) => state.blogs);

  return (
    <DashboardLayout>
      <section className="home-section">
        <section>
          <InteractiveLessonCard />
          <div className="space"></div>

          <DashBoardTeacherCard
            title="Your Language Exchange Partners are Waiting"
            buttoText="Find A Partner"
            onButtonClick={() => {
              navigate("/partners");
            }}
          />
          <div className="space"></div>
          <DashBoardTeacherCard
            title="Your Language Teachers Are Waiting"
            buttoText="Find A Teacher"
            onButtonClick={() => {
              navigate("/teachers");
            }}
          />
        </section>
        <section>
          <div className="space"></div>
          <NavLink to="/schedule">
            <ScheduleCard />
          </NavLink>
          <div className="space"></div>
          {loading ? <p>Loading</p> : <BlogCard data={data} />}
        </section>
      </section>
    </DashboardLayout>
  );
};
