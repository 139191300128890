import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import { gameTips } from "../../../mock/game-tips";
import { Icon } from "@/components";

export const GameLoading = () => {
  const tip = gameTips[0];
  const { activity_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(
        `/activity/lessons/${activity_id}/brief/trivia-activity/${activity_id}`
      );
    }, 5000);

    return () => clearTimeout(timer);
  }, [activity_id, navigate]);

  return (
    <div className="game-loading-container">
      <div className="content-container">
        <Icon name="logo" width={70} height={70} />
        <h1 className="title">{tip.title}</h1>
        <p className="desc">{tip.desc}</p>
        <div className="loading-container">
          <p className="loading-text">Loading . . .</p>
        </div>
      </div>
    </div>
  );
};
