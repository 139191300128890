import { useState } from "react";
import { TextInput, Button } from "@/components";
import RegisterationHeader from "./registrationHeader";

interface StepNameProps {
  formData: { first_name: string; last_name: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  nextStep: () => void;
}

export function Names({ formData, handleChange, nextStep }: StepNameProps) {
  // const [nameDate, setNameData] = useState<{
  //   firstName: string;
  //   lastName: string;
  // }>({ firstName: "", lastName: "" });

  return (
    <>
      <RegisterationHeader title="What is your name" />

      <form
        className="signin_form"
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
      >
        <TextInput
          label={"First Name"}
          type="text"
          placeholder="Firt Name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required={true}
        />

        <TextInput
          label={"Last Name"}
          type="text"
          placeholder="Last Name"
          name="last_name"
          value={formData.last_name}
          className=""
          onChange={handleChange}
        />

        <Button variant="primary" className="signin_btn" type="submit">
          Continue
        </Button>
      </form>
    </>
  );
}
