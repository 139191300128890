import { useRef, useEffect, useState } from "react";
import Button from "../Button";
import { FillTheGapQuestion as FillTheGapQuestionType } from "@/types/activity";

// convert this to a function expression.
export const WordChipOption = ({
  option,
  selectedOptionId,
  canCheck,
  onClick,
  is_answer,
}: {
  option: FillTheGapQuestionType["options"][number];
  canCheck: boolean;
  selectedOptionId: string | null;
  onClick: (option_id: string) => void;
  is_answer: boolean;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const playAudio = () => {
    audioRef.current?.play();
  };

  const pauseAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  };

  useEffect(() => {
    if (selectedOptionId === option.id) playAudio();
    else pauseAudio();
  }, [selectedOptionId, option]);

  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(option.id === selectedOptionId)}
      data-correct={String(
        canCheck && option.id === selectedOptionId && is_answer
      )}
      data-wrong={String(
        canCheck && option.id === selectedOptionId && !is_answer
      )}
      onClick={() => {
        onClick(option.id);
      }}
    >
      <p>{option.text}</p>
      <audio
        src={option.audioUrl}
        ref={audioRef}
        onPlay={() => setIsAudioPlaying(true)}
        onPause={() => setIsAudioPlaying(false)}
      ></audio>
    </Button>
  );
};
