import { activities_ } from "@/mock/activties";
import { ProgressBar, Icon, Button } from "@/components";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";
import { Question as QuestionType } from "../../../types/activity";
import { Question } from "./Question";

export default function Activity() {
  const activities = activities_;
  const [questionIndex, setQuestionIndex] = useState(0);
  const activity_id = useParams()["activity_id"];
  const activity = activities.find((a) => a.id === activity_id);

  console.warn(activity?.lessonPackages.levels[0].activities.length);
  const completion_percent = activity
    ? (questionIndex + 1) / activity.lessonPackages.levels[0].activities.length
    : 0;

  const lessons = useMemo(
    () => activity?.lessonPackages.levels[0].activities ?? [],
    [activity]
  );
  const currentQuestion = useMemo(
    () => lessons[questionIndex],
    [questionIndex, lessons]
  );

  const navigate = useNavigate();

  return (
    <div className="single_activity--container">
      <div className="top">
        <Button variant="neutral" onClick={() => navigate(-2)}>
          <Icon name="close" />
        </Button>
        <ProgressBar percent={completion_percent} />
      </div>
      <Question
        question={currentQuestion}
        handleNextQuestion={() => {
          setQuestionIndex((index) => {
            if (index === lessons.length - 1) {
              navigate(
                `/activity/lessons/${activity_id}/brief/trivia-activity/${activity_id}/complete`
              );
              return 0;
            } else {
              return index + 1;
            }
          });
        }}
      />
    </div>
  );
}
