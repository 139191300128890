import { Activity, InteractiveLessons } from "@/types/activity";
import levelOne from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 1 img.svg";
import levelTwo from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 2 img.svg";
import levelThree from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 3 img.svg";
import levelFour from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 4 img.svg";
// import levelFive from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 5 img.svg";
// import levelSix from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 6 img.svg";
// import levelSeven from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 7 img.svg";
// import levelEight from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 8 img.svg";
// import levelNine from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 9 img.svg";
// import levelTen from "@/pages/activity/level-icon/Level-Icons-unzipped/Level 10 img.svg";

// const lesson2: Activity = {
//   id: "0001",
//   title: "Learn Key word and meaning",
//   description:
//     "At the end of this session, you will learn new words and their meaning",
//   level: "A1",
//   image: "/activity.png",
//   icon: levelTwo,
//   activities: [
//     {
//       activityType: "MatchTheCorrectWord",
//       instruction: "Match the correct word",
//       question: {
//         text: "Boy",
//       },
//       answer: ["0010"],
//       options: [
//         {
//           id: "0010",
//           text: "Nwoke",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
//         },
//         {
//           id: "0011",
//           text: "Nwanyi",
//           audioUrl: "",
//         },
//         {
//           id: "0012",
//           text: "Na",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000E.mp3",
//         },
//         {
//           id: "0013",
//           text: "Nwata",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/011F.mp3",
//         },
//       ],
//     },
//     {
//       activityType: "MatchTheCorrectWord",
//       instruction: "Match the correct word",
//       question: {
//         text: "Girl",
//       },
//       answer: ["0011"],
//       options: [
//         {
//           id: "0010",
//           text: "Nwoke",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
//         },
//         {
//           id: "0011",
//           text: "Nwanyi",
//           audioUrl: "",
//         },
//         {
//           id: "0012",
//           text: "Na",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000E.mp3",
//         },
//         {
//           id: "0013",
//           text: "Nwata",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/011F.mp3",
//         },
//       ],
//     },
//     {
//       activityType: "MatchTheCorrectWord",
//       instruction: "Match the correct word",
//       question: {
//         text: "na",
//       },
//       answer: ["0012"],
//       options: [
//         {
//           id: "0010",
//           text: "Nwoke",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
//         },
//         {
//           id: "0011",
//           text: "Nwanyi",
//           audioUrl: "",
//         },
//         {
//           id: "0012",
//           text: "Na",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000E.mp3",
//         },
//         {
//           id: "0013",
//           text: "Nwata",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/011F.mp3",
//         },
//       ],
//     },
//     {
//       activityType: "TapInWhatYouHear",
//       instruction: "Tap in what yu hear",
//       question: {
//         audioUrl:
//           "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
//         text: "nwoke na nwanyi",
//       },
//       answer: ["0010", "0012", "0011"],
//       options: [
//         {
//           id: "0010",
//           text: "Nwoke",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
//         },
//         {
//           id: "0011",
//           text: "Nwanyi",
//           audioUrl: "",
//         },
//         {
//           id: "0012",
//           text: "Na",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000E.mp3",
//         },
//         {
//           id: "0013",
//           text: "Nwata",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/011F.mp3",
//         },
//       ],
//     },
//     {
//       activityType: "FillTheGapActivity",
//       instruction: "Fill in the gap",
//       question: "nwoke _ nwanyi",
//       answer: ["0012"],
//       options: [
//         {
//           id: "0010",
//           text: "Nwoke",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
//         },
//         {
//           id: "0011",
//           text: "Nwanyi",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
//         },
//         {
//           id: "0012",
//           text: "Na",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
//         },
//         {
//           id: "0013",
//           text: "Nwata",
//           audioUrl:
//             "https://lams-assets.s3.us-east-1.amazonaws.com/audio/011F.mp3",
//         },
//       ],
//     },
//     {
//       activityType: "CrossWordActivity",
//       instruction: "cross word",
//       question: [{ text: "nwoke" }, { text: "na" }, { text: "nwanyi" }],

//       //       answer: ["0020", "0022", "0021"],
//       //       options: [
//       //         {
//       //           id: "0020",
//       //           text: "boy",
//       //         },
//       //         {
//       //           id: "0022",
//       //           text: "and",
//       //         },
//       //         {
//       //           id: "0021",
//       //           text: "girl",
//       //         },
//       //       ],
//       //     },
//       //   ],
//       // };

//       // const lesson3: Activity = {
//       //   id: "0003",
//       //   title: "Learn Key word and meaning",
//       //   description:
//       //     "At the end of this session, you will learn new words and their meaning",
//       //   level: "A1",
//       //   image: "/activity.png",
//       //   icon: "/list-card-icon.png",
//       //   activities: [
//       //     {
//       //       activityType: "VideoContentActivity",
//       //       instruction: "Select the correct Option",
//       //       question: {
//       //         videoUrl:
//       //           "https://lams-assets.s3.us-east-1.amazonaws.com/video/YB-0001.mp4",
//       //       },
//       //       answer: "0001",
//       //       options: [
//       //         {
//       //           id: "0001",
//       //           text: "Báwo ni?",
//       //         },
//       //         {
//       //           id: "0002",
//       //           text: "ẹ ṣé",
//       //         },
//       //         {
//       //           id: "0003",
//       //           text: "ó dàbọ̀",
//       //         },
//       //         {
//       //           id: "0004",
//       //           text: "jọ̀ọ́",
//       //         },
//       //       ],
//     },
//   ],
// };

export const activities_: Activity[] = [
  {
    id: "1",
    title: "Level A1 (Beginner)",
    level: "A1",
    description:
      "At this level, learners focus on fundamental vocabulary, basic grammar structures, and essential communication skills.",
    image: "/activity.png",
    icon: levelOne,
    lessonPackages: {
      title: "Level A1 Lesson packages",
      levels: [
        {
          title: "Introduction To Igbo LanguageFundamentals & Vocabulary",
          subTitle: "At this level, learners focus on fundamental vocabulary",
          description: "By the end of this lesson, a learner will",
          lesson: "Lesson 1",
          exercise: 6,
          goal: "Learn how to construct simple Igbo phrases, sentences and the use of a basic conjunction.",
          desc: "By the end of this lesson, a learner will:",
          points: [
            "You will Learn how to construct simple Igbo phrases, sentences and the use of a basic conjunction.",
            "Understand how to make basic sentences in Igbo",
            `Master the use of the conjunction "na" (and)`,
          ],
          id: "1",
          activities: [
            {
              activityType: "VideoContentActivity",
              instruction: "Select the correct Option",
              question: {
                videoUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/video/0003.mp4",
              },
              answer: "0001",
              options: [
                {
                  id: "0001",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
                  text: "Male and Female",
                },
                {
                  id: "0002",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0015.mp3",
                  text: "Chika and Eze  welcome",
                },
                {
                  id: "0003",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Spoon",
                },
                {
                  id: "0004",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Plate",
                },
              ],
            },
            {
              activityType: "SelectCorrectImageActivity",
              instruction: "Select the correct Image",
              question: {
                audioUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
              },
              answer: "0001",
              options: [
                {
                  id: "0001",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0002.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
                  text: "Nwoke",
                },
                {
                  id: "0002",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0001.svg",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
                  text: "Nwanyi",
                },
                {
                  id: "0003",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0004.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Ngaji",
                },
                {
                  id: "0004",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0000.svg",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Efere",
                },
              ],
            },
            {
              activityType: "VideoContentActivity",
              instruction: "Select the correct Option",
              question: {
                videoUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/video/0003.webm",
              },
              answer: "0001",
              options: [
                {
                  id: "0001",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
                  text: "Male and Female",
                },
                {
                  id: "0002",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0015.mp3",
                  text: "Chika and Eze  welcome",
                },
                {
                  id: "0003",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Spoon",
                },
                {
                  id: "0004",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Plate",
                },
              ],
            },
            {
              activityType: "SelectCorrectImageActivity",
              instruction: "Select the correct Image",
              question: {
                audioUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
              },
              answer: "0004",
              options: [
                {
                  id: "0003",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0004.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Ngaji",
                },
                {
                  id: "0001",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0002.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
                  text: "Nwoke",
                },
                {
                  id: "0002",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0001.svg",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
                  text: "Nwanyi",
                },
                {
                  id: "0004",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0000.svg",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Efere",
                },
              ],
            },
            {
              activityType: "VideoContentActivity",
              instruction: "Select the correct Option",
              question: {
                videoUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/video/0001.mp4",
              },
              answer: "0002",
              options: [
                {
                  id: "0001",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
                  text: "Male and Female",
                },
                {
                  id: "0002",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0015.mp3",
                  text: "Chika and Eze  welcome",
                },
                {
                  id: "0003",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Spoon",
                },
                {
                  id: "0004",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Plate",
                },
              ],
            },

            {
              activityType: "FillTheGapActivity",
              instruction: "Fill in the Gap",
              question: "Nwoke _ Nwanyi",
              answer: ["0007"],
              options: [
                {
                  id: "0008",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Efere",
                },
                {
                  id: "0009",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Ngaji",
                },
                {
                  id: "0007",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
                  text: "na",
                },
              ],
            },
            {
              activityType: "TapInWhatYouHear",
              instruction: "Tap in What you hear",
              question: {
                audioUrl:
                  "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000D.mp3",
              },
              answer: ["0005", "0007", "0006"],
              options: [
                {
                  id: "0005",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
                  text: "Nwoke",
                },
                {
                  id: "0006",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
                  text: "Nwanyi",
                },
                {
                  id: "0008",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100D.m4a",
                  text: "Efere",
                },
                {
                  id: "0007",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
                  text: "na",
                },
                {
                  id: "0009",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Ngaji",
                },
              ],
            },
            {
              activityType: "CrossWordActivity",
              instruction: "Match the right options",
              question: [
                {
                  text: "Male",
                },
                {
                  text: "and",
                },
                {
                  text: "female",
                },
              ],
              answer: ["0005", "0007", "0006"],
              options: [
                {
                  id: "0005",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
                  text: "Nwoke",
                },
                {
                  id: "0006",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/1001.m4a",
                  text: "Nwanyi",
                },
                {
                  id: "0007",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
                  text: "na",
                },
              ],
            },
            {
              activityType: "MatchTheCorrectWord",
              instruction: "Select the right word",
              question: {
                text: "Welcome",
              },
              answer: ["0010"],
              options: [
                {
                  id: "0001",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0002.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/000C.mp3",
                  text: "Nwoke",
                },
                {
                  id: "0010",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/010A.mp3",
                  text: "Nnonu",
                },
                {
                  id: "0007",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/0003.mp3",
                  text: "na",
                },
                {
                  id: "0003",
                  imageUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/graphics/0004.png",
                  audioUrl:
                    "https://lams-assets.s3.us-east-1.amazonaws.com/audio/100F.m4a",
                  text: "Ngaji",
                },
              ],
            },
          ],
        },
      ],
    },
  },
];
// lesson2,
// lesson3,

export const lessons_: InteractiveLessons[] = [
  {
    id: "0001",
    title: "Learn Key word and meaning",
    description:
      "At the end of this session, you will learn new words and their meaning",
    level: "A1",
    image: "/activity.png",
    icon: "/list-card-icon.png",
  },
];
