import "./styles.scss"

const LessonList = ({ lessons }: { lessons: any[] }) => {
  return (
    <div className="lesson-list">
      <h2 className="lesson-list-title">Lesson Packages</h2>
      {lessons.map((lesson, index) => (
        <div key={index} className="lesson-card">
          <h3 className="lesson-card-title">{lesson.title}</h3>
          <p className="lesson-card-subtitle">Lesson {lesson.number}</p>
        </div>
      ))}
    </div>
  );
};

export default LessonList;
