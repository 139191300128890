import DashboardLayout from "@/layouts/dashboard-layout";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { activities_ } from "../../../mock/activties";
import { Icon } from "@/components";
import truncateString from "@/utils/truncateString";

export const Brief = () => {
  const { activity_id } = useParams();
  const navigate = useNavigate();

  const activity = activities_.find((a) => a.id === activity_id);
  const item = activity?.lessonPackages?.levels[0]!;

  return (
    <DashboardLayout>
      <div className="brief">
        <div className="brief__content">
          <h1 className="brief__title">Lesson Brief</h1>
          <h2 className="brief__package-title">{item.title}</h2>
          <p className="brief__lesson-exercise">
            {item.lesson} ・ {item.exercise} Exercise
          </p>

          <p className="brief__base-text">Your Learning Goal</p>

          <p className="brief__base-text">
            Description: <span className="brief__goal">{item.description}</span>
          </p>

          {item.points.map((each, index) => (
            <div key={index} className="brief__con">
              <Icon name="checkGray" />
              <p className="brief__point">{truncateString(each, 80)}</p>
            </div>
          ))}
        </div>

        <div className="brief__button-container">
          <button
            onClick={() =>
              navigate(`/activity/lessons/${item.id}/brief/activity-loading`)
            }
            className="brief__button"
          >
            Start
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};
