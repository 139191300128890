import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { useState, useMemo, useRef, useEffect } from "react";
import { Verdict } from "../../Verdict";
import { FillTheGapQuestion as FillTheGapQuestionType } from "@/types/activity";
import "./styles.scss";
import { AudioBtn } from "@/components";
import { WordChipOption } from "@/components/WordChipOption";

export const FillTheGapQuestion = ({
  question,
  handleNextQuestion,
}: {
  question: FillTheGapQuestionType;
  handleNextQuestion: () => void;
}) => {
  const answers = question.answer;
  const [selectedOptionId, setSelectedOptionId] = useState<string | null>(null);
  const [canCheck, setCanCheck] = useState(false);
  const answerOption = useMemo(
    () => question.options.find((o) => question.answer.includes(o.id)),
    [question]
  );

  const handleClickOption = (optionId: string) => {
    setCanCheck(false);
    setSelectedOptionId((prev) => (prev === optionId ? null : optionId));
  };

  const handleCheck = () => setCanCheck(true);
  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>Fill in the Gap</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="question">
        {question.imageUrl && (
          <img
            className="question-image"
            src={question.imageUrl}
            alt={question.question}
          />
        )}
        {question.audioUrl && <AudioBtn audioUrl={question.audioUrl} />}
        <h1 className="question-text">{question.question} </h1>
      </div>

      <div className="options">
        {question.options.map((option) => (
          // <FillTheGapOption
          //   onClick={handleClickOption}
          //   canCheck={canCheck}
          //   option={option}
          //   selectedOptionId={selectedOptionId}
          //   key={option.id}
          //   is_answer={answers.includes(option.id)}
          // />

          <WordChipOption
            onClick={handleClickOption}
            canCheck={canCheck}
            option={option}
            selectedOptionId={selectedOptionId}
            key={option.id}
            is_answer={answers.includes(option.id)}
          />
        ))}
      </div>

      <Button
        disabled={selectedOptionId === null}
        variant="primary"
        className="check-btn"
        onClick={handleCheck}
      >
        Check
      </Button>
      <Verdict
        is_answer={answerOption?.id === selectedOptionId}
        answer={answerOption?.text ?? "No answer"}
        open={canCheck}
        onClose={() => setCanCheck(false)}
        handleNext={handleNextQuestion}
      />
    </div>
  );
};

const FillTheGapOption = ({
  option,
  selectedOptionId,
  canCheck,
  onClick,
  is_answer,
}: {
  option: FillTheGapQuestionType["options"][number];
  canCheck: boolean;
  selectedOptionId: string | null;
  onClick: (option_id: string) => void;
  is_answer: boolean;
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  const playAudio = () => {
    audioRef.current?.play();
  };

  const pauseAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  };

  useEffect(() => {
    if (selectedOptionId === option.id) playAudio();
    else pauseAudio();
  }, [selectedOptionId, option]);

  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(option.id === selectedOptionId)}
      data-correct={String(
        canCheck && option.id === selectedOptionId && is_answer
      )}
      data-wrong={String(
        canCheck && option.id === selectedOptionId && !is_answer
      )}
      onClick={() => {
        onClick(option.id);
      }}
    >
      <p>{option.text}</p>
      <audio
        src={option.audioUrl}
        ref={audioRef}
        onPlay={() => setIsAudioPlaying(true)}
        onPause={() => setIsAudioPlaying(false)}
      ></audio>
    </Button>
  );
};
