import { ILesson } from "@/types/schedules";
import { useNavigate } from "react-router-dom";
import { formatDateString, formatTimeSlot } from "@/utils/function";
import "./schedule-styles.scss";

export const ScheduleCard = ({ item: lesson }: { item: ILesson }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (lesson.isCompleted) {
      navigate(`./completed/${lesson.id}`, {
        state: { lesson },
      });
    } else {
      navigate(`./upcoming/${lesson.id}`, {
        state: { lesson },
      });
    }
  };

  return (
    <div
      className={`schedule-card--container-${
        lesson.isCompleted ? "completed" : "upcoming"
      }`}
      onClick={handleClick}
    >
      <div className="row">
        <div className="left">
          <div className="date">
            {formatDateString(new Date(lesson.time?.start))}
          </div>
          <div className="time">
            <span>{formatTimeSlot(new Date(lesson.time?.start))}</span> -{" "}
            <span>{formatTimeSlot(new Date(lesson.time?.end))}</span>
          </div>
        </div>
        <p className="language">{lesson.language}</p>
      </div>
    </div>
  );
};
