import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@/components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.scss";
import { learningProgress } from "@/mock/game-tips";
import DashboardLayout from "@/layouts/dashboard-layout";
import truncateString from "@/utils/truncateString";

export const Complete = () => {
  const accuracy = 80;
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="completion">
        <div className="completion__scroll-view">
          <h1 className="completion__title">Lesson Completed</h1>

          <div className="completion__progress">
            <div className="completion__progress-circle">
              <CircularProgressbar
                value={accuracy}
                text={``}
                styles={buildStyles({
                  textColor: "#127FBE",
                  pathColor: "#127FBE",
                  trailColor: "#E1E8ED",
                  textSize: "16px",
                  strokeLinecap: "round",
                })}
                counterClockwise={true}
              />
              <p className="completion__accuracy">
                Accuracy <br /> {accuracy}%
              </p>
            </div>
            <div className="completion__details">
              <p className="completion__details-text">Completion Time</p>
              <p className="completion__details-time">2:30</p>
            </div>
          </div>

          <h2 className="completion__sub-title">Your Learning Progress</h2>

          {learningProgress.map((item, index) => (
            <div className="completion__learnt" key={index}>
              <Icon name="checkBlue" />
              <p className="completion__learnt-text">
                {truncateString(item, 80)}
              </p>
            </div>
          ))}
        </div>

        <div className="completion__modal">
          <Icon name="share" color="#169FED" />
          <button
            className="completion__modal-btn"
            onClick={() => navigate("/activity")}
          >
            Continue
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};
