import DashboardLayout from "@/layouts/dashboard-layout";
import "./styles.scss";
import LessonList from "./LessonList";
import { lessons_ } from "@/mock/activties";
import { FaArrowLeft } from "react-icons/fa";

export function LessonPackages() {
  return (
    <DashboardLayout>
      <div style={{display:"flex",alignItems:"center", gap:"5px"}}>
        <FaArrowLeft />
      <span>Back</span>
      </div>
      <div>
        <LessonList lessons={lessons_} />
      </div>
    </DashboardLayout>
  );
}
