import { Button, Icon } from "@/components";
import { ProfileCard } from "../../EditProfile/ProfileCard";
import { IProfile } from "@/types/profile";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import SetTimeZone from "./SetTimeZone";
import {
  updateUserProfile,
  fetchProfile,
  fetchEmailVerify,
} from "@/redux/slice/profile/profile";
import { debounce, camelToSnakeCase } from "@/utils/function";
import { useAuth } from "@/contexts/auth-context";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

export default function GeneralSettings({
  profile,
  onClose,
}: {
  profile: IProfile;
  onClose: () => void;
}) {
  const [temProfile, setTempProfile] = useState({ ...profile });

  useEffect(() => setTempProfile(profile), [profile]);

  //@ts-ignore
  const timezones = Intl.supportedValuesOf("timeZone").map((value, index) => ({
    id: `${index}`,
    value,
  }));

  const [selectedTZ, setSelectedTZ] = useState<
    (typeof timezones)[number] | null
  >(timezones[0]);

  const [activeSetting, setActiveSetting] = useState("");
  const dispatch = useAppDispatch();
  const { verify } = useAppSelector((state) => state.profileSlice);
  const { token } = useAuth();
  useEffect(() => {
    dispatch(
      fetchEmailVerify({ token: token || "", email: temProfile.email || "" })
    );
  }, [dispatch]);

  const debouncedDispatch = useRef(
    debounce((name: keyof IProfile, value: string | Array<string>) => {
      dispatch(
        updateUserProfile({ token: token!, profileData: { [name]: value } })
      ).then(() => {
        dispatch(fetchProfile({ token: token! }));
      });
    }, 700)
  ).current;

  const handleProfileEdit = (
    value: string | Array<string>,
    name: keyof IProfile
  ) => {
    setTempProfile((p) => ({ ...p, [name]: value }));
  };

  const handleBlur = (name: keyof IProfile) => {
    const toBeConverted = ["firstName", "lastName", "dateOfBirth"];
    let snakeCaseName = name;
    if (toBeConverted.includes(snakeCaseName)) {
      snakeCaseName = camelToSnakeCase(name.toString()) as keyof IProfile;
    }
    debouncedDispatch(snakeCaseName, temProfile[name] as string);
  };

  if (activeSetting === "timezone")
    return (
      <div className="active-setting-edit">
        <Button
          variant="neutral"
          className="back-btn"
          onClick={() => setActiveSetting("")}
        >
          <Icon name="left_arrow" />
          <span>Back</span>
        </Button>
        <SetTimeZone
          list={timezones}
          selected_item={selectedTZ}
          onChange={(timezone) => setSelectedTZ(timezone)}
        />
      </div>
    );
  return (
    <div className="general-settings">
      <Button variant="neutral" className="back-btn" onClick={onClose}>
        <Icon name="left_arrow" />
        <span>Back</span>
      </Button>

      <h1 className="heading">General Settings</h1>

      <div className="list">
        <ProfileCard
          title={
            <p
              className="email-title"
              data-verified={String(temProfile?.isVerified)}
            >
              Email{" "}
              {verify?.emailVerified ? (
                <span style={{ color: "green" }}>Verified</span>
              ) : (
                <span style={{ color: "red" }}>Unverified</span>
              )}
            </p>
          }
          value={temProfile.email}
          onClick={() => null}
          type="text"
          isEditable
          onChange={(value) => handleProfileEdit(value, "email")}
        />
        {/* <ProfileCard
          title={"Phone Number"}
          value={temProfile?.}
          onClick={() => null}
          type="text"
          isEditable
          onChange={(value) => handleProfileEdit(value, "phone")}
        /> */}
        <ProfileCard
          title={"Timezone"}
          value={selectedTZ?.value ?? ""}
          onClick={() => setActiveSetting("timezone")}
          type="text"
          isEditable
          onChange={() => null}
        />
        <ProfileCard
          title={"Time format"}
          value={"12H"}
          onClick={() => null}
          type="text"
          isEditable
          onChange={() => null}
        />
      </div>
    </div>
  );
}
