import { useState, useRef } from "react";
import Button from "../Button";
import Icon from "../Icon";

export default function AudioBtn({ audioUrl }: { audioUrl: string }) {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const playAudio = () => {
    if (isAudioPlaying) {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    } else audioRef.current?.play();
  };

  return (
    <Button variant="neutral" className="audio_container" onClick={playAudio}>
      <Icon name="audio" />
      <audio
        src={audioUrl}
        ref={audioRef}
        onPlay={() => setIsAudioPlaying(true)}
        onPause={() => setIsAudioPlaying(false)}
      ></audio>
    </Button>
  );
}
