import { Button } from "@/components";
import styles from "./patners-card.module.scss";
import Icon from "@/components/Icon";

interface cardProps {
  setVisible: React.Dispatch<React.SetStateAction<number>>;
}

export default function PartnersCard({ setVisible }: cardProps) {
  return (
    <article className={styles.patnerCard}>
      <div className="headerx">
        <h2 className={styles.heading}>Language Partner Sessions</h2>{" "}
        <p className={styles.sub_heading}>Speak better by practicing with native speakers</p>
      </div>

      <div>
        <div>You are entitled to two (2) partner session per week.</div>
        <div className={styles.info}>
          <Icon name="checks" />
          Pick a date and time that suits you.
        </div>
        <div className={styles.info}>
          <Icon name="checks" color="#169fed" />
          Choose a partner
        </div>
      </div>

      <div className={styles.slots_available}>
        <div>Your Available Session Slots</div>
        <div style={{ fontWeight: "700", fontSize: "34px" }}>2</div>
      </div>

      <Button variant="primary" onClick={() => setVisible(1)}>
        Book A Session
      </Button>
    </article>
  );
}
