import DashboardLayout from "@/layouts/dashboard-layout";
import { activities_ } from "@/mock/activties";
import ActivityCard from "./activity-card";
import "./styles.scss";

export function Activties() {
  const activities = activities_;

  return (
    <DashboardLayout>
      <div>
        {activities.map((activity, index) => (
          <ActivityCard key={index} activity={activity} />
        ))}
      </div>
    </DashboardLayout>
  );
}
