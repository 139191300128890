export const gameTips = [
  {
    id: 1,
    title: "SANKOFA",
    desc: "Go back and get it! A symbol of the wisdom of learning from the past to build for the future.",
  },
  {
    id: 2,
    title: "Akoma Ntoaso",
    desc: "Unity of hearts! It signifies the unity of hearts or the bond of togetherness among people. .",
  },
  {
    id: 3,
    title: "KRAPA",
    desc: " It symbolizes self-reliance the need for individuals to be self-sufficient and proactive in achieving their goals.",
  },
  {
    id: 4,
    title: "NSEREWA",
    desc: `"Nserewa" is an Akan symbol representing excellence, genuineness, and authenticity."`,
  },
  {
    id: 5,
    title: "TABONO",
    desc: `"Tabono" is an Akan symbol representing strength, perseverance, and hard work.`,
  },
];

export const learningProgress = [
  "You Learnt how to construct simple Igbo phrases, sentences and the use of a basic conjunction.",
  "You now understand how to make basic sentences in Igbo.",
  `You have now mastered the use of the conjunction "na" (and).`,
];
