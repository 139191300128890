import { useState, ChangeEvent } from "react";
import { Button, TextInput, Icon } from "@/components";
import AuthLayout from "@/layouts/auth-layout";
import { Names } from "./names";

interface FormData {
  first_name: "";
  last_name: "";
  email: string;
  date_of_birth: string;
  password: string;
}

export default function Register() {
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<FormData>({
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    password: "",
  });

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Navigate steps
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const registerUser = () => {
    console.log("registering user");
  };

  // Handle final form submission
  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   console.log("Form Submitted:", formData);
  //   alert("Registration successful!");
  // };

  return (
    <AuthLayout>
      <div className="signin">
        {step > 1 && (
          <Button variant="neutral" className="go-back" onClick={prevStep}>
            <Icon name="left_arrow" />
          </Button>
        )}

        {step === 1 && (
          <Names
            formData={formData}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        )}
        {step === 2 && (
          <DateOfBirth
            date_of_birth={formData.date_of_birth}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        )}
        {step === 3 && (
          <Email
            email={formData.email}
            handleChange={handleChange}
            nextStep={nextStep}
          />
        )}
        {step === 4 && (
          <Password
            password={formData.password}
            handleChange={handleChange}
            nextStep={registerUser}
          />
        )}
      </div>
    </AuthLayout>
  );
}

interface DateOfBirthProps {
  date_of_birth: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  nextStep: () => void;
}

function DateOfBirth({
  date_of_birth,
  handleChange,
  nextStep,
}: DateOfBirthProps) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        nextStep();
      }}
    >
      <TextInput
        label={"Last Name"}
        type="date"
        placeholder="Date of Birth"
        name="date_of_birth"
        value={date_of_birth}
        className=""
        onChange={handleChange}
      />

      <Button variant="primary" className="signin_btn" type="submit">
        Continue
      </Button>
    </form>
  );
}

interface EmailProps {
  email: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  nextStep: () => void;
}

function Email({ email, handleChange, nextStep }: EmailProps) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        nextStep();
      }}
    >
      <TextInput
        type="email"
        label={"Email"}
        placeholder="Email"
        name="email"
        value={email}
        className=""
        onChange={handleChange}
      />

      <Button variant="primary" className="signin_btn" type="submit">
        Continue
      </Button>
    </form>
  );
}

interface PasswordProps {
  password: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  nextStep: () => void;
}

function Password({ password, handleChange, nextStep }: PasswordProps) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        nextStep();
      }}
    >
      <TextInput
        type="password"
        label={"Password"}
        placeholder="****"
        name="password"
        value={password}
        className=""
        onChange={handleChange}
      />

      <Button variant="primary" className="signin_btn" type="submit">
        Register
      </Button>
    </form>
  );
}

// function ParentalConsent() {
//   return <form></form>;
// }
