import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardLayout from "@/layouts/dashboard-layout";
import PatnersCard from "./partners-card";
import { ScheduleCard } from "../home/interactive-lesson-card";
import ReactCalendar, { type CalendarProps as BaseCalendarProps } from "react-calendar";
import styles from "./calendar.module.scss";
import "../../App.css";
import Icon from "../../components/Icon";
import { endOfWeek, startOfWeek } from "date-fns";
import { Button } from "@/components";
import { formatStringtoDate } from "@/utils/function";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface dateTime {
  date: Value | null;
  time: string;
  partner: partnerDetails;
  comtools: string;
}

interface partnerDetails {
  name: string;
  rating: number;
  sessions: number;
  about: string;
  comtools: string[];
}
export default function Partners() {
  const [visible, setVisible] = useState<number>(0);
  const [viewProfile, setViewProfile] = useState<partnerDetails | null>();
  const [booking, setBooking] = useState<dateTime>({
    date: new Date(),
    time: "",
    partner: {} as partnerDetails,
    comtools: "",
  });

  const addPartener = (id: number, e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (id !== undefined) {
      const partner = partnerData.find((_, index) => index === id);
      if (partner) {
        setBooking((prev) => ({ ...prev, partner: partner }));
      }
    }
  };

  const handleChange = (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setBooking((prev) => ({
      ...prev,
      date: value,
    }));
  };

  let arrayTime = [];

  for (let i = 0; arrayTime.length < 24; i++) {
    arrayTime.push(`${i}:00 am- ${i}:30am`);
    if (i >= 11) {
      arrayTime.push(`${i}:00 pm- ${i}:30pm`);
    }
  }

  const handleProfile = (id: number) => {
    if (id !== undefined) {
      console.log(id);
      const partner = partnerData.find((_, index) => index === id);
      if (partner) {
        setViewProfile(partner);
        setVisible(6);
      }
    }
  };

  const [partnerData, setPartnerData] = useState([
    {
      name: "John Doe",
      rating: 5,
      sessions: 60,
      about:
        "Hello, My name is Kaduna Nzoegwu. I am a Nigerian and I live in Nigeria. I am a Language Teacher and I have been teaching for over 6 years. I have taught in different primary/secondary schools and language centers. I studied languages and linguistics. I am currently doing my Masters in French as a foreign language and also a TEFL course. I am also a DELF DALF examiner. I love teaching and look forward to helping people with their Igbo language and French language.",
      comtools: ["meet", "teams", "zoom"],
    },
    {
      name: "Quill Mofi",
      rating: 4.5,
      sessions: 60,
      about:
        "Hello, My name is Kaduna Nzoegwu. I am a Nigerian and I live in Nigeria. I am a Language Teacher and I have been teaching for over 6 years. I have taught in different primary/secondary schools and language centers. I studied languages and linguistics. I am currently doing my Masters in French as a foreign language and also a TEFL course. I am also a DELF DALF examiner. I love teaching and look forward to helping people with their Igbo language and French language.",
      comtools: ["meet", "zoom"],
    },
    {
      name: "Caleb Darwin",
      rating: 3,
      sessions: 60,
      about:
        "Hello, My name is Kaduna Nzoegwu. I am a Nigerian and I live in Nigeria. I am a Language Teacher and I have been teaching for over 6 years. I have taught in different primary/secondary schools and language centers. I studied languages and linguistics. I am currently doing my Masters in French as a foreign language and also a TEFL course. I am also a DELF DALF examiner. I love teaching and look forward to helping people with their Igbo language and French language.",
      comtools: ["meet", "teams"],
    },
  ]);

  return (
    <DashboardLayout>
      <section className="home-section">
        <section>
          <PatnersCard setVisible={setVisible} />
        </section>
        <div className={styles.stepsbox}>
          {visible !== 0 && (
            <button
              className={styles.calClose}
              onClick={() => {
                if (visible !== 3) {
                  setVisible(visible - 1);
                } else {
                  setVisible(2);
                }
              }}
            >
              {visible === 1 ? (
                <>
                  {" "}
                  <Icon name="close" />
                  <p>Close</p>
                </>
              ) : (
                <>
                  <Icon name="left_arrow" />
                  <p>Back</p>
                </>
              )}
            </button>
          )}
          {visible === 1 ? (
            //calendar and time picker
            <div className={styles.calendar}>
              <div className={styles.calSpace}>
                <ReactCalendar
                  className={styles.recCal}
                  onChange={handleChange}
                  value={booking["date"]}
                  maxDate={endOfWeek(new Date())}
                  minDate={startOfWeek(new Date())}
                />
              </div>

              <select
                className={styles.selects}
                onChange={(e) => {
                  setBooking((prev) => ({
                    ...prev,
                    time: e.target.value,
                  }));
                }}
              >
                <option>Select time</option>
                {arrayTime.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>

              <div className={styles.contBtn}>
                <Button
                  variant="primary"
                  className={`${styles.calBtn} ${
                    (!booking.date || !booking.time) && `${styles.disabled}`
                  }`}
                  onClick={() => {
                    setVisible(2);
                  }}
                  disabled={!booking.date || !booking.time}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : visible === 2 ? (
            // select a partner
            <div className={styles.box2}>
              <p>Select a partner</p>
              <div className={styles.pBox}>
                {partnerData?.map((item, index) => (
                  <div
                    key={index}
                    className={styles.partners}
                    typeof="button"
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => addPartener(index, e)}
                  >
                    <div className={styles.avatar}></div>
                    <div>
                      <h3>{item.name}</h3>
                      <div className={styles.funcs}>
                        <p>{item.rating}</p>
                        <p>{item.sessions} sessions</p>
                      </div>
                      <button className={styles.profil} onClick={() => handleProfile(index)}>
                        view Profile
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <div className={styles.contBtn}>
                <Button
                  variant="primary"
                  className={`${styles.calBtn} ${
                    !Object.keys(booking.partner).length && `${styles.disabled}`
                  }`}
                  onClick={() => {
                    setVisible(3);
                    console.log("it's disabled");
                  }}
                  disabled={!Object.keys(booking.partner).length}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : visible === 6 ? (
            //View partner profile
            <div className={styles.extend}>
              <h4>Partner profile</h4>
              <div className={styles.extendBox}>
                <div className={styles.avatar}></div>
                <div>
                  <h3>{viewProfile?.name}</h3>
                  <div className={styles.funcs}>
                    <p>{viewProfile?.rating}</p>
                    <p>{viewProfile?.sessions} sessions</p>
                  </div>
                </div>
              </div>
              <div>
                <p className={styles.surm}>Surmary</p>
                <p>{viewProfile?.about}</p>
              </div>
            </div>
          ) : visible === 3 ? (
            <div className={styles.comtools}>
              <h4>Select Session Room</h4>
              {booking?.partner?.comtools?.map((item, index) => (
                <div
                  key={index}
                  className={styles.comtoolsBox}
                  onClick={() => setBooking((prev) => ({ ...prev, comtools: item }))}
                >
                  <Icon name={item as "meet" | "teams" | "zoom"} height={30} width={30} />
                  {item === "meet" ? (
                    <p>Google meet</p>
                  ) : item === "zoom" ? (
                    <p>Zoom</p>
                  ) : (
                    <p>teams</p>
                  )}
                </div>
              ))}

              <div className={styles.contBtn}>
                <Button
                  variant="primary"
                  className={`${styles.calBtn} ${!booking.comtools && `${styles.disabled}`}`}
                  onClick={() => {
                    setVisible(4);
                  }}
                  disabled={!booking.comtools}
                >
                  Continue
                </Button>
              </div>
            </div>
          ) : visible === 4 ? (
            <div>
              <h4>Confirm Booking</h4>
              <div className={styles.confirm}>
                <div className={styles.langType}>
                  <h4>Language Partner Session</h4>
                  <p>Igbo language - 30minutes session</p>
                </div>

                <div className={styles.avsect}>
                  <div className={styles.avatar}></div>
                  <p>{booking?.partner?.name}</p>
                </div>

                <div className={styles.sessect}>
                  <div className={styles.sesHead}>
                    <p>Session time</p>
                    <button onClick={() => setVisible(1)}>
                      <Icon name="edit" width={20} height={20} />
                    </button>
                  </div>

                  <div className={styles.sesbody}>
                    <p className={styles.sesdate}>
                      {booking?.date instanceof Date
                        ? formatStringtoDate(booking.date.toLocaleDateString())
                        : ""}
                    </p>

                    <p className={styles.sestime}>{booking?.time}</p>
                  </div>
                </div>
              </div>
              <div className={styles.contBtn}>
                <Button
                  variant="primary"
                  className={`${styles.calBtn} ${!booking.comtools && `${styles.disabled}`}`}
                  onClick={() => {
                    setVisible(5);
                  }}
                  disabled={!booking.comtools}
                >
                  Confirm Booking
                </Button>
              </div>
            </div>
          ) : (
            <section>
              {/* <div className="space"></div> */}
              <NavLink to="/schedule">
                <ScheduleCard />
              </NavLink>
            </section>
          )}
        </div>
      </section>
    </DashboardLayout>
  );
}
