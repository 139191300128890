import { useState } from "react";
import "./styles.scss";
import { Activity } from "@/types/activity";
import ActivityDetailCard from "../ActivityDetailCard";
import ActivityListCard from "../interactive-list-card";

export default function ActivityCard({ activity }: { activity: Activity }) {
  const [isActivityDetailCardVisible, setIsActivityDetailCardVisible] =
    useState(false);

  const handleToggleVisibility = () => {
    setIsActivityDetailCardVisible((prev) => !prev);
  };



  return (
    <section className="activity-card">
      <ActivityListCard activity={activity} onClick={handleToggleVisibility} />
      {isActivityDetailCardVisible && (
        <ActivityDetailCard activity={activity} />
      )}
    </section>
  );
}
