import DashboardLayout from "@/layouts/dashboard-layout";
import "./styles.scss";
import { Link, useParams } from "react-router-dom";
import { activities_ } from "@/mock/activties";

export const Lessons = () => {
  const { activity_id } = useParams();
  const activity = activities_.find((e) => e.id === activity_id);

  return (
    <DashboardLayout>
      <div>
        <h3>{activity?.lessonPackages.title}</h3>
        <Link
          to={`/activity/lessons/${activity?.id}/brief`}
          className="package-card-container"
        >
          <h3 className="package-title">
            {activity?.lessonPackages.levels[0]?.title}
          </h3>
          <p className="package-lesson">
            {activity?.lessonPackages.levels[0]?.lesson}
          </p>
        </Link>
      </div>
    </DashboardLayout>
  );
};
