import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { useState, useMemo, useEffect } from "react";
import { Verdict } from "../../Verdict";
import { CrossWordMatchQuestion as CrossWordMatchQuestionType } from "@/types/activity";
import "./styles.scss";
import { AudioBtn } from "@/components";

export const CrossWordMatchQuestion = ({
  question,
  handleNextQuestion,
}: {
  question: CrossWordMatchQuestionType;
  handleNextQuestion: () => void;
}) => {
  const [answeredIds, setAnsweredIds] = useState<string[]>([]);
  const [selectedOptionId, setSelectedOptionId] = useState<{
    question: string | null;
    answer: string | null;
  }>({ question: null, answer: null });

  const answers = question.answer;
  const is_answer = useMemo(() => {
    if (answeredIds.length === question.options.length * 2) return true;
    if (!(selectedOptionId.answer && selectedOptionId.question)) return false;
    const questionIndex = +selectedOptionId.question;

    const answerIndex = answers.findIndex(
      (answer_id) => answer_id === selectedOptionId.answer
    );

    return questionIndex === answerIndex;
  }, [answers, selectedOptionId, answeredIds, question.options]);

  const answer = useMemo(() => {
    if (answeredIds.length === question.options.length * 2) return "All Done";
    if (!(selectedOptionId.answer && selectedOptionId.question)) return "";
    const firstOption = question.question[+selectedOptionId.question];

    const secondOption = question.options.find(
      (option) => option.id === selectedOptionId.answer
    );

    return `${firstOption?.text} = ${secondOption?.text}`;
  }, [selectedOptionId, question.options, question.question, answeredIds]);

  const canCheck = useMemo(() => {
    return (
      answeredIds.length === question.options.length * 2 ||
      (!!selectedOptionId.answer && !!selectedOptionId.question && !is_answer)
    );
  }, [selectedOptionId, is_answer, answeredIds, question.options]);

  const handleClickOption = (optionId: string, type: "answer" | "question") => {
    if (type === "question") {
      setSelectedOptionId((o) => ({ ...o, question: optionId }));
    } else {
      setSelectedOptionId((o) => ({ ...o, answer: optionId }));
    }
  };

  useEffect(() => {
    if (!is_answer) return;
    setAnsweredIds((answeredIds) =>
      selectedOptionId.answer && selectedOptionId.question
        ? Array.from(
            new Set([
              ...answeredIds,
              selectedOptionId.answer,
              selectedOptionId.question,
            ])
          )
        : answeredIds
    );
    if (selectedOptionId.answer || selectedOptionId.question)
      setSelectedOptionId({ question: null, answer: null });
  }, [is_answer, selectedOptionId]);

  const handleNext = () => {
    if (answeredIds.length === question.options.length * 2)
      handleNextQuestion();
    else {
      setAnsweredIds((answeredIds) =>
        selectedOptionId.answer && selectedOptionId.question
          ? Array.from(
              new Set([
                ...answeredIds,
                selectedOptionId.answer,
                selectedOptionId.question,
              ])
            )
          : answeredIds
      );
      setSelectedOptionId({ question: null, answer: null });
    }
  };

  return (
    <div className="question_container" data-type={question.activityType}>
      <div className="top">
        <h1>Cross word match</h1>
        <div className="language_container">
          <Icon name="language" />
        </div>
      </div>
      <div className="middle">
        <div className="questions">
          {question.question.map((question, index) => (
            <CrossWordMatchQuestionQuestion
              onClick={handleClickOption}
              canCheck={canCheck}
              question={question}
              selectedOptionId={selectedOptionId}
              // key={question.text + index}
              key={index}
              index={index}
              is_answer={
                is_answer && selectedOptionId.question === index.toString()
              }
              has_been_answered={answeredIds.includes(index.toString())}
            />
          ))}
        </div>
        <div className="options">
          {question.options.map((option) => (
            <CrossWordMatchOption
              onClick={handleClickOption}
              canCheck={canCheck}
              option={option}
              selectedOptionId={selectedOptionId}
              key={option.id}
              is_answer={is_answer && selectedOptionId.answer === option.id}
              has_been_answered={answeredIds.includes(option.id)}
            />
          ))}
        </div>
      </div>

      <Verdict
        is_answer={is_answer}
        answer={answer}
        open={canCheck}
        onClose={() =>
          setSelectedOptionId((options) =>
            answeredIds.length === question.options.length * 2
              ? { question: null, answer: null }
              : { question: options.question, answer: null }
          )
        }
        handleNext={handleNext}
      />
    </div>
  );
};

const CrossWordMatchOption = ({
  option,
  selectedOptionId,
  canCheck,
  onClick,
  is_answer,
  has_been_answered,
}: {
  option: CrossWordMatchQuestionType["options"][number];
  canCheck: boolean;
  selectedOptionId: {
    question: string | null;
    answer: string | null;
  };
  onClick: (option_id: string, type: "answer" | "question") => void;
  is_answer: boolean;
  has_been_answered: boolean;
}) => {
  return (
    <Button
      variant="neutral"
      key={option.id}
      className="option"
      data-selected={String(selectedOptionId.answer === option.id)}
      data-correct={String(
        canCheck &&
          selectedOptionId.answer === option.id &&
          (has_been_answered || is_answer)
      )}
      data-wrong={String(
        canCheck &&
          selectedOptionId.answer === option.id &&
          !is_answer &&
          !has_been_answered
      )}
      data-has-been-answered={String(has_been_answered)}
      onClick={() => {
        onClick(option.id, "answer");
      }}
      disabled={has_been_answered}
    >
      {has_been_answered && <Icon name="correct" />}
      <p>{option.text}</p>
    </Button>
  );
};

const CrossWordMatchQuestionQuestion = ({
  question,
  index,
  is_answer,
  has_been_answered,
  onClick,
  canCheck,
  selectedOptionId,
}: {
  question: CrossWordMatchQuestionType["question"][number];
  index: number;
  onClick: (option_id: string, type: "answer" | "question") => void;
  is_answer: boolean;
  has_been_answered: boolean;
  canCheck: boolean;
  selectedOptionId: {
    question: string | null;
    answer: string | null;
  };
}) => {
  return (
    <Button
      variant="neutral"
      className="option"
      data-selected={String(selectedOptionId.question === index.toString())}
      data-correct={String(
        canCheck &&
          selectedOptionId.question === index.toString() &&
          (has_been_answered || is_answer)
      )}
      data-wrong={String(
        canCheck &&
          selectedOptionId.question === index.toString() &&
          !is_answer &&
          !has_been_answered
      )}
      data-has-been-answered={String(has_been_answered)}
      onClick={() => {
        onClick(index.toString(), "question");
      }}
      disabled={has_been_answered}
    >
      {has_been_answered && <Icon name="correct" />}

      {question.text && <p>{question.text}</p>}
      {question.audioUrl && <AudioBtn audioUrl={question.audioUrl} />}
    </Button>
  );
};
