import { Activity } from "@/types/activity";
import "./styles.scss";
import { ProgressBar, Button } from "@/components";
import { Link } from "react-router-dom";

interface ActivityDetailCardProps {
  activity: Activity;
}

export default function ActivityDetailCard({
  activity,
}: ActivityDetailCardProps) {
  return (
    <div className="activity_card">
      <div className="top">
        <img src={activity.image ?? undefined} alt="" />
      </div>
      <div className="bottom">
        <h1>{activity.title}</h1>
        <h2>
          {0}/{activity.lessonPackages.levels?.length ?? 0} lessons
        </h2>
        <ProgressBar
          height={16}
          percent={(0 / (activity.lessonPackages.levels?.length || 1)) * 100}
        />
        <p>{activity.description}</p>
        <Link to={`/activity/lessons/${activity.id}`}>
          <Button variant="secondary">Start</Button>
        </Link>
      </div>
    </div>
  );
}

///activity/${activity.id}
