import { Activity } from "@/types/activity";
import "./styles.scss";
import { FaChevronDown } from "react-icons/fa";

interface ActivityListCardProp {
  activity: Activity;
  onClick: () => void;
}

export default function ActivityListCard({
  activity,
  onClick,
}: ActivityListCardProp) {
  console.warn(activity.lessonPackages.levels);
  return (
    <header className="activity-list-card" onClick={onClick}>
      <div className="activity-list-card-content">
        <img
          className="activity-list-card-icon"
          src={activity.icon}
          alt="Activity Icon"
        />
        <div className="activity-list-card-details">
          <h3 className="activity-list-card-title">{activity.title}</h3>
          <p className="activity-list-card-lessons">
            {0}/{activity.lessonPackages.levels.length} Lessons
          </p>
        </div>
      </div>
      <FaChevronDown size={24} className="activity-list-card-chevron" />
    </header>
  );
}
